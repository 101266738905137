<template>
	<v-scale-screen width="1920" height="1080">
		<div id="">
			<!--<YuanHeader></YuanHeader>-->
			<router-view/>
		</div>
		<div class="goHome">
			<span class="homePage">
				<!--<a href="#header">^</a>-->
				<a href="#header"><img src="../src/assets/up.png" alt="" srcset=""></a>
			</span>
		</div>
		<div class="fixedDiv">
			<div class="fixed fixedBottom">
				<div class="fixedImage">
					<img src="../src/assets/5.jpg" alt="">
				</div>
				<div class="fixed1">
					公众号
				</div>
			</div>
			<div class="fixed fixedBottom">
				<div class="fixedImage">
					<img src="../src/assets/6.jpg" alt="">
				</div>
				<div class="fixed1">
					小程序
				</div>
			</div>
			<div class="fixed">
				<div class="fixedImage">
					<img src="../src/assets/5.png" alt="">
				</div>
				<div class="fixed1">
					商务合作
				</div>
			</div>
		</div>
	</v-scale-screen>
</template>

<script setup>
	
</script>

<style scoped>
	/*pc端*/
	@media screen and (min-width:769px){
		.goHome{
				  width: 50px;
				  height: 30px;
				  position: fixed;
				  top: 0;
				  right: 0;
				  background-color: rgb(109,171,255);
		}
		.homePage{
				  width: 50px;
				  height: 30px;
				  cursor: pointer;
				  border: 0;
				  display: flex;
				  justify-content: center;
				  align-items: center;
				  text-align: center;
		}
		.homePage a{
			color: #fff;
			font-size: 15px;
			text-decoration: none;
		}
		.homePage img{
			width: 50%;
			height: 50%;
		}
		.fixedDiv{
				  width: 5%;
				  height: 36%;
				  position: fixed;
				  bottom: 2px;
				  right: 5px;
		}
		.fixed{
			display: block;
				  width: 100%;
				  height: 32%;
				  display: block;
		}
		.fixedBottom{
			margin-bottom: 2%;
		}
		.fixedImage{
			width: 100%;
			height: 80%;
		}
		.fixedImage img{
				  height: 100%;
				  width: 100%;
		}
		.fixed1{
			clear: both;
			display: block;
			width: 100%;
			height:20%;
			font-size: 0.5rem;
			text-align: center;
			padding-top: 0;
			color: #666;
		}
	}
	/*移动端*/
	@media screen and (max-width:769px){
		.goHome{
			display: none;
		}
		.fixedDiv{
			display: none;
		}
	}
	

</style>
