<template>
	<div class="div_swiper">
	        <div id="swiper" class="swiper" @mousemove="showSwiperButton()" @mouseout="hiddenSwiperButton()">
	            <div class="swiper-wrapper">
	                <div class="swiper-slide">
	                    <img class="slide_img" src="@/assets/11.png" />
	                </div>
	                <div class="swiper-slide">
	                    <img class="slide_img" src="@/assets/11.png" />
	                </div>
					<div class="swiper-slide">
					    <img class="slide_img" src="@/assets/11.png" />
					</div>
	            </div>
	            <div class="swiper-button-prev" id="prev"></div>
	            <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
	            <div class="swiper-button-next" id="next"></div>
	            <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
	            <div class="swiper-pagination"></div>
	            <!--分页器。如果放置在swiper外面，需要自定义样式。-->
	        </div>
	    </div>
</template>

<script setup>
	import {nextTick, ref, watch, toRefs, onMounted, onBeforeUnmount, getCurrentInstance} from "vue";
	import "swiper/swiper-bundle.min.css";  // 所有 Swiper 样式，包括所有模块样式（如导航、分页等）
	import Swiper, { Navigation, Pagination, Scrollbar, Autoplay } from "swiper"; // 导入您需要的模块
	const showSwiperButton = () => {
		let d1 = document.getElementById('prev');
		d1.style.cssText = "display:block;"
		let d2 = document.getElementById('next');
		d2.style.cssText = "display:block;"
	}
	const hiddenSwiperButton = () => {
		let d1 = document.getElementById('prev');
		d1.style.cssText = "display:none;"
		let d2 = document.getElementById('next');
		d2.style.cssText = "display:none;"
	}
	onMounted(() => {
		nextTick(()=>{
			let swiper=new Swiper(".swiper", {
			    //speed: 500,//播放的速度
			    //spaceBetween: 2000,// 轮播图之间的间距
				//spaceBetween: 30,
				//centeredSlides: true,
			    loop: true,//是否循环播放
			    //autoplay: {
			    //    delay: 2000,//自动播放的间隔
			    //},
				//autoplay:true,
				autoplay: {
				          delay: 2500,
				          disableOnInteraction: false,
				},
				//autoplayStopOnLast:true,
				//autoplay:{
				//	stopOnLastSlide:true,
				//},
			    modules: [Navigation, Pagination, Scrollbar, Autoplay],
			    navigation: {
			        nextEl: ".swiper-button-next",//前一个按钮
			        prevEl: ".swiper-button-prev",//后一个按钮
			    },
			    scrollbar: {
			        el: ".swiper-scrollbar",
			        draggable: true,
			    },
			    pagination: {
			        el: '.swiper-pagination',
			        clickable: true,
			        bulletClass : 'my-bullet',//需设置.my-bullet样式
			        bulletActiveClass: 'my-bullet-active',
			    },
			});
		})
	})
</script>

<style>
	
	.div_swiper {
	    width: 100%;
	    height: 100%;
	}
	
	.swiper {
	    height: 100%;
	    width: 100%;
	}
	
	.swiper-wrapper {
	    width: 100%;
	    height: 100%;
	
	}
	
	.swiper-slide {
	    width: 100%;
	    height: 100%;
	    margin-right: 0px;
	    position: relative;
	}
	
	/*.div_swiper {
	    width: 100%;
	    height: 100%;
	}
	
	.swiper {
	    height: 100%;
	    width: 100%;
	}
	
	.swiper-wrapper {
	    width: 100%;
	    height: 100%;
	
	}
	
	.swiper-slide {
	    width: 100%;
	    height: 100%;
	    margin-right: 0px;
	    position: relative;
	}*/
	
	.slide_img {
	    width: 100%;
	}
	
	.swiper-button-prev {
	
	    display: none;
	}
	
	.swiper-button-next {
	
	    display: none;
	}
	 
	.my-bullet{
	 
	    width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,100px));
	    height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,3px));
	    display: inline-block;
	    background: var(--swiper-pagination-bullet-inactive-color,#000);
	    opacity: var(--swiper-pagination-bullet-inactive-opacity, .2);
	    margin: 0 7px;
	    cursor: pointer;
	    border: 0;
	}
	
	.my-bullet-active{
	    background: #ffffff;
	    opacity: 1;
	}
</style>