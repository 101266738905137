import {createWebHistory,createRouter} from 'vue-router'
let routes = [
	{
	    path: '/',
	    redirect: '/home',
	},
	{
	    path: '/home',
	    name: 'YuanHome',
	    component: () => import("../views/YuanHome/home")
	},
	{
	    path: '/maternal',
	    name: 'MaternalHealth',
	    component: () => import("../views/MaternalHealth/maternal")
	},
	{
	    path: '/women',
	    name: 'WomenHealth',
	    component: () => import("../views/WomenHealth/women")
	},
	{
	    path: '/child',
	    name: 'ChildHealth',
	    component: () => import("../views/ChildHealth/child")
	},
	{
	    path: '/rehability',
	    name: 'ChildRehability',
	    component: () => import("../views/ChildRehability/rehability")
	},
	{
	    path: '/delivery',
	    name: 'DeliveryCenter',
	    component: () => import("../views/DeliveryCenter/delivery")
	},
	{
	    path: '/genetic',
	    name: 'GeneticCenter',
	    component: () => import("../views/GeneticCenter/genetic")
	},
	{
	    path: '/GansuMch',
	    name: 'GansuMch',
	    component: () => import("../views/GansuMch/GansuMch")
	},
	{
	    path: '/GansuCenter',
	    name: 'GansuCenter',
	    component: () => import("../views/GansuCenter/GansuCenter")
	},
	{
	    path: '/NorthwestMch',
	    name: 'NorthwestMch',
	    component: () => import("../views/NorthwestMch/NorthwestMch")
	},
	{
	    path: '/WeinanFirst',
	    name: 'WeinanFirst',
	    component: () => import("../views/WeinanFirst/WeinanFirst")
	},
]
//const createRouter = () => new router({
//    mode: 'history',
//    routes: routes
//})

const router = createRouter({
    //mode: 'history',
	history: createWebHistory('/YJKJ/'),
    routes: routes
});

export default router