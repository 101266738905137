<template>
	<!--<div class="footer">
		<div class="recommend">
			<div class="logo">
				<img src="@/assets/logo1.png" alt="">
			</div>
			<div class="name">
				元界科技（西安）有限公司
			</div>
			<div class="address">
				地址：陕西省西安市高新区锦业路69号创业园五洲科技C楼202室
			</div>
		</div>
		<div class="aboutContent">
			<div class="about1">
				<img src="@/assets/5.png" alt="">
				<p>联系人</p>
			</div>
			<div class="about1">
				<img src="@/assets/6.jpg" alt="">
				<p>小程序</p>
			</div>
			<div class="about1">
				<img src="@/assets/5.jpg" alt="">
				<p>商务合作</p>
			</div>
		</div>
		<div class="contact">
			<div class="content">
				<div class="content1">
					联系我们 
				</div>
				<div class="content1">
					电话：17209000114
				</div>
				<div class="content1">
					邮箱：inigee@inigee.com
				</div>
			</div>
		</div>
		<div class="company">
			<div class="company1">
				Copyright © 2023 · 元界科技（西安）有限公司
			</div>
			<div class="company1">
				陕ICP备2022008133号-2
			</div>
		</div>
	</div>-->
	<div class="footer">
		<div class="recommend">
			<div class="link">
				<div class="about2 needR">
					<p>关于我们</p>
					<a href="">中心简介</a>
					<a href="">机构设置</a>
					<a href="">联系我们</a>
				</div>
				<div class="about2 needR">
					<p>用户指南</p>
					<a href="">登记指南</a>
					<a href="">用户帮助中心</a>
				</div>
				<div class="about2">
					<p>友情链接</p>
					<a href="">中国版权保护中心</a>
					<a href="">国家新闻出版署</a>
					<a href="">中华版权代理有限公司</a>
				</div>
			</div>
			<div class="contact">
				<div class="copyRight">
					版权所有：陕ICP备2022008133号-2
				</div>
				<div class="detail">
					<div class="info address">
						地址：西安市雁塔区高新路88号
					</div>
					<div class="info postal">
						邮编：710065
					</div>
					<div class="info tele">
						电话：17209000114
					</div>
				</div>
			</div>
		</div>
		<div class="aboutContent">
			<div class="image">
				<div class="about1">
					<img src="@/assets/5.png" alt="">
					<p>商务合作</p>
				</div>
				<div class="about1">
					<img src="@/assets/6.jpg" alt="">
					<p>小程序</p>
				</div>
				<div class="about1">
					<img src="@/assets/5.jpg" alt="">
					<p>公众号</p>
				</div>
			</div>
		</div>
	</div>
	<div class="moveFooter">
		<div class="detail">
			<div class="info address">
				西安市雁塔区高新路88号
			</div>
			<div class="info copyRight">
				陕ICP备2022008133号-2
			</div>
		</div>
		<div class="image">
			<div class="about1 needPadding">
				<img src="@/assets/5.png" alt="">
				<p>商务合作</p>
			</div>
			<div class="about1 needPadding">
				<img src="@/assets/6.jpg" alt="">
				<p>小程序</p>
			</div>
			<div class="about1">
				<img src="@/assets/5.jpg" alt="">
				<p>公众号</p>
			</div>
		</div>
		<!--<div class="contact">
			<div class="detail">
				<div class="info address">
					地址：西安市雁塔区高新路88号
				</div>
				<div class="copyRight">
					版权所有：陕ICP备2022008133号-2
				</div>
			</div>
		</div>
		<div class="aboutContent">
			<div class="image">
				<div class="about1">
					<img src="@/assets/5.png" alt="">
					<p>商务合作</p>
				</div>
				<div class="about1">
					<img src="@/assets/6.jpg" alt="">
					<p>小程序</p>
				</div>
				<div class="about1">
					<img src="@/assets/5.jpg" alt="">
					<p>公众号</p>
				</div>
			</div>
		</div>-->
	</div>
</template>

<script setup>

</script>

<style scoped>
	/*pc端*/
	@media screen and (min-width:769px){
		.footer {
			background-color: #eaeaea;
			width: 100%;
			height: 40vh;
		}
		.recommend{
			width: 50%;
			height: 100%;
			float: left;
		}
		.aboutContent{
			width: 50%;
			height: 100%;
			float: left;
		}
		.image{
			width: 80%;
			height: 100%;
			margin-right: 20%;
		}
		.about1{
			float: right;
			width: 18%;
			height: 50%;
			display: block;
			margin-top: 11%;
			padding-right: 3%;
		}
		.about1 img{
			width: 100%;
			height: 75%;
		}
		.about1 p{
			clear: both;
			display: block;
			width: 100%;
			height: 15%;
			font-size: 0.9rem;
			text-align: center;
			padding-top: 0;
			color: #666;
		}
		.link{
			width: 70%;
			height: 70%;
			margin-left: 25%;
			border-bottom: 1px solid #999;
		}
		.about2{
			width: 30%;
			height: 80%;
			margin-top: 7%;
			float: left;
		}
		.needR{
			margin-right: 5%;
		}
		.contact{
			width: 100%;
			height: 25%;
			clear: both;
			display: block;
			margin-left: 25%;
		}
		.copyRight{
			display: block;
			font-size: 0.9rem;
			color: #666;
			margin-bottom: 3px;
			margin-top: 3px;
		}
		.detail{
			display: block;
		}
		.info{
			float: left;
			margin-right: 3%;
			color: #666;
			font-size: 0.9rem;
		}
		.about2 p{
			display: block;
			font-size: 1.2rem;
			clear: both;
			font-weight: bold;
		}
		.about2 a{
			display: block;
			font-size: 0.9rem;
			color: #666;
			text-decoration: none;
			clear: both;
			margin-bottom: 3px;
		}
		.moveFooter{
			display: none;
		}
	}
	/*移动端*/
	@media screen and (max-width:769px){
		.footer{
			display: none;
		}
		.moveFooter{
			height: 25vh;
			width: 100%;
			background-color: #eaeaea;
		}
		.detail{
			text-align: center;
			width: 100%;
			display: block;
			height: 35%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align:center;
		}
		.detail .info{
			font-size: 0.8rem;
			width: 80%;
			color: #666;
		}
		.image{
			width: 70%;
			height: 65%;
			margin: 0 auto;
		}
		.about1{
			float: left;
			width: 30%;
			/*height: 50%;*/
			display: block;
		}
		.needPadding{
			padding-right: 5%;
		}
		.about1 img{
			width: 100%;
			height: 100%;
		}
		.about1 p{
			clear: both;
			display: block;
			width: 100%;
			height: 15%;
			font-size: 0.5rem;
			text-align: center;
			padding-top: 0;
			color: #666;
		}
	}
</style>
