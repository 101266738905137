import { createApp } from 'vue'
import router from './router'
import VScaleScreen from 'v-scale-screen'
import App from './App.vue'
import YuanHeader from './components/YuanHeader/header.vue'
import YuanSwiper from './components/YuanSwiper/swiper.vue'
import YuanHome from './views/YuanHome/home.vue'
import YuanFooter from './components/YuanFooter/footer.vue'
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})


const app = createApp(App)
app.component('YuanHeader', YuanHeader)
app.component('YuanSwiper', YuanSwiper)
app.component('YuanHome', YuanHome)
app.component('YuanFooter', YuanFooter)

app.use(router)
app.use(VScaleScreen)

app.mount('#app')
